.adminlistproduct {
    background-color: white;
    /* padding: 20px; */
    padding-top: 20px;
    padding-bottom: 450px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.adminlistproduct_header {
    justify-content: center;
    display: flex;
}

.adminlistproduct_heading {
    justify-content: space-between;
    display: flex;
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
    /* width: 92%; */
    background-color: white;
    width: 100%;
    /* gap: 17px; */
}

.adminlistproduct_heading label {
    color: #74829F;
    font-size: .8rem;
    font-weight: 550;
}

.adminlistproduct_middle {
    padding-top: 20px;
    padding-bottom: 20px;
}

.adminlistproduct_end {
    justify-content: center;
    display: flex;
}

.adminlistproduct_end button {
    padding: 7px;
    border-radius: 18px;
    border: none;
    outline: none;
    color: #6C7A99;
    font-weight: 550;
    font-size: .7rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.singleadminlistproduct {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.singleadminlistproduct_row {
    display: flex;
    justify-content: space-between;
    /* padding-left: 10px; */
    background-color: #FAFBFC;
    /* width: 92%; */
    align-items: center;
    padding: 20px;
    border-radius: 5px;
    /* background-color: #F6F5FF; */
    width: 100%;
    gap: 10px;

}

.singleadminlistproduct_row_flag input {
    border: none;
    outline: none;
    width: 4.09%;
}

.singleadminlistproduct_row_img {
    /* display: flex; */
    display: flex;
    align-items: center;
}

.singleadminlistproduct_row img {
    border-radius: 3px;
    padding-left: 10px;
    /* display: flex; */
    /* border-bottom-right-radius: 3px; */
}

.singleadminlistproduct_row label,
span {
    font-size: .7rem;
    color: #74829F;
    font-weight: 550;
}

.singleadminlistproduct_row_img {
    width: 9.09%;
    /* display: flex;
    justify-content: center; */
}

.singleadminlistproduct_row_producer {
    width: 15.09%;
    /* background-color: green; */
}

.singleadminlistproduct_row_product {
    width: 10.09%;
    /* padding-left: 10px; */
}

.singleadminlistproduct_row_id {
    width: 9.7%;
}

.singleadminlistproduct_row_cat {
    width: 9.09%;
    padding-left: 15px;
}

.singleadminlistproduct_row_link {
    width: 10.09%;
}

.singleadminlistproduct_row_cost {
    width: 10%;
}

.singleadminlistproduct_row_extra {
    width: 9.09%;
}

.singleadminlistproduct_row_price {
    width: 8.09%;
    padding-left: 10px;
    padding-right: 10px;
}

.singleadminlistproduct_row_priority {
    width: 7.09%;
    padding-left: 10px;
}

.singleadminlistproduct_row_edit {
    width: 4.09%;
}

.adminlistproduct_heading_img {
    display: flex;
    width: 4%;
    justify-content: center;
}

.adminlistproduct_heading_producer {
    width: 14%;
    margin-left: -5px;
    display: flex;
    justify-content: center;

}

.adminlistproduct_heading_product {
    width: 12%;
    padding-left: 15px;
    display: flex;
    justify-content: center;

}

.adminlistproduct_heading_id {
    width: 8%;
    display: flex;
    padding-left: 18px;
    /* justify-content: center; */


}

.adminlistproduct_heading_cat {
    width: 8%;
    display: flex;
    justify-content: center;

}

.adminlistproduct_heading_link {
    width: 9%;
    padding-left: 35px;
    /* display: flex;
    justify-content: center; */

}

.adminlistproduct_heading_cost {
    width: 8%;
    padding-left: 10px;
}

.adminlistproduct_heading_extra {
    width: 8%;
    padding-left: 10px;
}

.adminlistproduct_heading_price {
    width: 8.09%;

}

.adminlistproduct_heading_priority {
    width: 8.09%;
    /* padding-left: 10px; */
}

.adminlistproduct_heading_edit {
    width: 4.09%;

}

.adminlistproduct_heading_flag {
    width: 5.09%;

}

.singleadminlistproduct :hover {
    background-color: rgb(207, 178, 234);
    color: white;
}

@media only screen and (max-width:500px) {

    /* .adminlistproduct_heading_id{
        display: none;
    }
    .singleadminlistproduct_row_id{
        display: none;
    } */
    .adminlistproduct_heading_producer {
        width: 11%;
    }

    .adminlistproduct_heading label {
        font-size: .6rem;
    }

    .singleadminlistproduct_row label {
        font-size: .5rem;
    }

    /* .singleadminlistproduct_row_link{
        width: 13.9%;
    } */
    .singleadminlistproduct_row {
        margin-right: 0px;
        padding-right: 5px;
    }
}

@media only screen and (max-width: 350px) {
    .adminlistproduct{
        width: 99.9%;
    }
    .adminlistproduct_heading_producer{
        padding-left: 0;
    }
    .adminlistproduct_heading label {
        font-size: .5rem;
    }

    .singleadminlistproduct_row label {
        font-size: .4rem;
    }

    .singleadminlistproduct_row {
        padding-left: 0px;
        /* width: 90%; */
    }
       
    .adminlistproduct_heading_id{
        display: none;
    }
    .singleadminlistproduct_row_id{
        display: none;
    }
}