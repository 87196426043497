.admineditproductpage_loader{
    position: absolute;
    margin-left: 50%;    
    margin-top: 20%;
}
.admineditproduct_row {
    display: flex;
    /* background-color: #F7F8FA; */
}

.admineditproduct_row_col1 {
    display: flex;
}

.admineditproduct_row_col2 {
    width: 80%;
    margin-left: 100px;
    margin-top: 80px;
    margin-bottom: 2%;
    justify-content: center;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width:500px){
    .admineditproduct_row_col2{
        margin-left: 5px;
        width: 98%;
    }
}