/* .admineditsubtitle_Titlemenu{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction:row;
    padding: 0 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
   border-bottom: 1px solid #EDEFF2;
}

.admineditsubtitle{
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: row;
    background-color: #FFFFFF;
    border-bottom: 1px solid #EDEFF2;
}
.admineditsubtitle_Titlemenu_col2{
    width:100% ;
    background-color: #FFFFFF;
    display: flex;
}
.admineditsubtitle_Titmenu_col2_co1
{
    padding: 21px 0;
}

.admineditsubtitle_Titmenu_col2_co1 label{
    color: #7D8FB3;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: .8rem;
    justify-content:space-around ;
    padding: 21px 0;
    margin-left: 10px;
    margin-right: 10px;
}
.admineditsubtitle_Titmenu_col2_co1 :hover {
    color:#8833FF;
    border-bottom: 2px solid #8833FF;
}
.admineditsubtitle_Titlemenu_col3{
    display: flex;
    justify-content:center;
    align-content: center;
    margin-bottom: 15px;
}
.admineditsubtitle_Titmenu_col3_iconbutton{
    background-color:#8833FF ;
    border: none;
    border-radius: 25px;
    display: flex;
    justify-content:center;
    flex-direction: row;
    text-align: center;
    align-items: center;
    margin-top: 15px;
    padding: 8px;
} */

/*****************************************************/


.admineditsubtitle_Titlemenu{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction:row;
    padding: 0 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
   border-bottom: 1px solid #EDEFF2;
}
/* .admineditsubtitle_Titmenu_col2_co1 label:focus-within {
    background-color: pink;
    color: #8833FF;
} */

.admineditsubtitle{
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: row;
    background-color: #FFFFFF;
    border-bottom: 1px solid #EDEFF2;
}
.admineditsubtitle_Titlemenu_col2{
    width:100% ;
    background-color: #FFFFFF;
    display: flex;
}
.admineditsubtitle_Titmenu_col2_co1
{
    padding: 21px 0;
}

.admineditsubtitle_Titmenu_col2_co1 label{
    color: #7D8FB3;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: .8rem;
    justify-content:space-around ;
    padding: 21px 0;
    margin-left: 10px;
    margin-right: 10px;
}
.admineditsubtitle_Titmenu_col2_co1 :hover {
    color:#8833FF;
    border-bottom: 2px solid #8833FF;
}
.admineditsubtitle_Titmenu_col2_co1 .selected {
    color: #8833FF;
    border-bottom: 2px solid #8833FF;
}
.cont2
{
    color:#3392ff;
}

.admineditsubtitle_Titlemenu_col3{
    /* width: 50%; */
    display: flex;
    justify-content:center;
    align-content: center;
    margin-bottom: 15px;
}
.admineditsubtitle_Titmenu_col3_iconbutton{
    /* width: 30%; */
    background-color:#8833FF ;
    border: none;
    border-radius: 25px;
    display: flex;
    justify-content:center;
    flex-direction: row;
    text-align: center;
    align-items: center;
    margin-top: 15px;
    padding: 8px;
}

/* @media only screen and (max-width:350px){
    .admineditsubtitle{
        width: 104%;
    }
} */
