/* @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap'); */

.AdminLogin_outer {
    /* width: 100%; */
    background-color: #F5F5F5;
    display: flex;
    justify-content: space-evenly;
}

.AdminLogin_container {
    width: 28%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
    padding-right: .5px;
    margin: 9.97% 0;
    position:absolute;
    box-shadow: 0px 0px 8px .5px grey;
}

.AdminLogin_inner_container {

    width: 100%;
    display: flex;
    justify-content: left;
    flex-direction: column;
    margin: 30px;


}

.AdminLogin_inncont_row1 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-left: 20px;
}

.AdminLogin_inncont_row1 img {
    /* width:20%; */
    width: 35px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
}

.AdminLogin_inncont_row1 label {
    /* width: 30%; */
    /* color: black; */
    display: flex;
    /* justify-content: left; */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 550;
    font-size: 2rem;
    line-height: 30px;

    padding-left: 10px;
    align-items: center;
    padding-bottom: 10px;
}

.AdminLogin_inncont_row2 {
    width: 100%;
    display: flex;
    justify-content: left;
    flex-direction: row;

    /* padding-bottom: 10px;
    padding-left: 10px; */
    padding: 10px;

}

.AdminLogin_inncont_row2 label {
    /* width: 37%; */
    color: black;
    font-weight: 550;
    font-size: 1.8rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    /* line-height: 30px; */

}

.AdminLogin_inncont_row2 img {
    /* width: 25%; */
    display: flex;
    justify-content: left;
    width: 35px;
    height: 25px;


    /* margin-left: 8%; */


}

.AdminLogin_inncont_row3 {
    width: 100%;
    display: flex;
    justify-content: left;
    color: #7E81A2;
    /* padding-bottom: 10px;
    padding-left: 10px; */
    padding: 10px;
}

.AdminLogin_inncont_row4 {
    width: 95%;
    display: flex;
    justify-content: left;
    align-items: center;
    border: .5px solid #7E81A2;
    border-radius: 4px;
    padding: 10px;
    padding-bottom: 5px;
    margin-bottom: 15px;

}

.AdminLogin_inncont_row4 input {
    width: 100%;
    display: flex;
    justify-content: left;
    border: none;
    padding: 10px;
    padding-bottom: 5px;
    outline: none;
    background-color: white;
}

.AdminLogin_inncont_row5 {
    width: 95%;
    display: flex;
    justify-content: left;
    align-items: center;
    border: .5px solid #7E81A2;
    border-radius: 4px;
    padding: 10px;
    padding-bottom: 5px;
    margin-bottom: 15px;
    text-align: center;
}

.AdminLogin_inncont_row5 input {
    width: 90%;
    display: flex;
    justify-content: left;
    align-items: center;
    border: none;
    padding: 10px;
    padding-bottom: 5px;
    outline: none;

}

.AdminLogin_inncont_row6 {
    width: 95%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    padding: 10px;
}

.AdminLogin_inncont_check_row1_col1 {
    width: 50%;
    display: flex;
    justify-content: left;

}

.AdminLogin_inncont_check_row1_col2 {
    width: 50%;
    display: flex;
    justify-content: right;

}

.AdminLogin_inncont_check_row1_col2 span {
    color: #9155fd;

}

.AdminLogin_inncont_row7 {
    width: 95%;
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    padding-bottom: 10px;


}

.AdminLogin_inncont_row7 button {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    color: white;
    background-color: #9155fd;
    border-radius: 5px;
    border: none;
    padding-bottom: 10px;

}

@media only screen and (max-width:500px){
    .AdminLogin_container{ 
        width: 80%;
    }
}

@media only screen and (max-width:350px){
    .AdminLogin_container{ 
        width: 80%;
    }
}