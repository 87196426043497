.adminaddproductmain {
    display: flex;
    background-color: #FFFFFF;
    padding: 20px;
    color: #6b7a99;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.7rem;
    font-weight: 500;
    /* width: 60%; */
  }
  .adminaddproductmain_col2_row2_inner_row3 input{
    border: none;
    outline: none;
    width: 100%;
  }
  .adminaddproductmain_col1 {
    width: 50%;
    background-color: #fafbfc;
    border-radius: 5px;
    padding: 30px;
    margin: 0 9px;
  }
  
  .adminaddproductmain_col2 {
    width: 50%;
    /* background-color: rgb(246, 170, 170); */
    /* margin: 0 9px; */
  }
  
  .adminaddproductmain_col1_row2_outer label {
    padding: 0 5px;
  }
  
  .adminaddproductmain_col1_row1 {
    justify-content: space-between;
    display: flex;
  }
  
  .adminaddproductmain_col1_row1 label {
    /* padding-left: 10px; */
  }
  
  .adminaddproductmain_col1_row2 {
    padding-top: 20px;
    /* background-color: red; */
    display: flex;
  }
  
  .adminaddproductmain_col1_row2_col1 {
    width: 50%;
    /* background-color: aliceblue; */
    margin-right: 5px;
  }
  
  .adminaddproductmain_col1_row2_col2 {
    width: 50%;
    /* background-color:brown; */
    margin-left: 5px;
  }
  
  .adminaddproductmain_col1_row2_col1_row,
  .adminaddproductmain_col1_row2_col2_row {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
  }
  
  .adminaddproductmain_col1_row2_col1_row label,
  .adminaddproductmain_col1_row2_col2_row label {
    padding-bottom: 8px;
    /* display: flex; */
  }
  
  .adminaddproductmain_col1_row2_col1_row input,
  .adminaddproductmain_col1_row2_col2_row input {
    width: 92%;
    padding: 9px;
    border: none;
    outline: none;
  }
  
  .adminaddproductmain_col1_row2_row1_col_input {
    background-color: white;
    /* width: 222px; */
  }
  
  .adminaddproductmain_col1_row2_row1_col_input select {
    width: 94%;
    border: none;
    outline: none;
    padding: 8px;
    color: #6b7a99;
    font-size: .7rem;
  }
  
  .adminaddproductmain_col1_row3 {
    width: 100%;
    /* background-color: blueviolet; */
  }
  
  .adminaddproductmain_col1_row3_row {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* background-color: aliceblue; */
  }
  
  .adminaddproductmain_col1_row3_row label {
    padding-bottom: 10px;
  }
  
  .adminaddproductmain_col1_row3_row_input {
    background-color: white;
    align-items: center;
    display: flex;
    padding-right: 10px;
  }
  
  .adminaddproductmain_col1_row3_row_input input {
    width: 90%;
    padding: 10px;
    border: none;
    outline: none;
  }
  
  .adminaddproductmain_col1_row3_row1 {
    padding-top: 15px;
    display: flex;
    flex-direction: column;
  }
  
  .adminaddproductmain_col1_row3_row1 label {
    padding-bottom: 8px;
  }
  
  .adminaddproductmain_col1_row3_row1 input {
    padding: 9px;
    border: none;
    outline: none;
  }
  
  /******************************box2 start*****************************/
  
  .adminaddproductmain_col2_row1 {
    background-color: #fafbfc;
    width: 100%;
    margin-bottom: 8px;
    border-radius: 5px;
  }
  
  .adminaddproductmain_col2_row1_outer {
    padding: 30px;
  }
  
  .adminaddproductmain_col2_row1_outer_row1 {
    width: 100%;
    justify-content: space-between;
    display: flex;
  }
  
  .adminaddproductmain_col2_row1_outer_row2 {
    display: flex;
    justify-content: space-between;
  }
  
  .adminaddproductmain_col2_row1_outer_row1 {
    padding-bottom: 20px;
  }
  
  .adminaddproductmain_col2_row1_outer_row2_col1_row {
    display: flex;
    flex-direction: column;
  }
  
  .adminaddproductmain_col2_row1_outer_row2_col1_row label {
    padding-top: 15px;
    padding-bottom: 10px;
  }
  
  .adminaddproductmain_col2_row1_outer_row2_col1_row input {
    width: 80px;
    padding: 9px;
    border: none;
    outline: none;
  }
  
  .adminaddproductmain_col2_row1_outer_row2_col1_row select {
    width: 90px;
    padding: 9px;
    border: none;
    outline: none;
    /* color: #c3cad9; */
    color:#6b7a99 ;
    font-size: .7rem;
  }
  
  
  .adminaddproductmain_col2_row1_outer_row2_col1_row_select {
    background-color: white;
  }
  
  
  /***********************box3 start***********************/
  
  
  .adminaddproductmain_col2_row2 {
    padding: 30px;
    margin-top: 8px;
    background-color: #fafbfc;
    padding-bottom: 40px;
    border-radius: 5px;
  }
  
  .adminaddproductmain_col2_row2_inner_row1 {
    display: flex;
    justify-content: space-between;
  }
  
  .adminaddproductmain_col2_row2_inner_row2 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .adminaddproductmain_col2_row2_inner_row2_row1 {
    justify-content: space-between;
    display: flex;
  }
  
  .adminaddproductmain_col2_row2_inner_row3 {
    padding-top: 20px;
  }
  
  .adminaddproductmain_col2_row2_inner_row3 label{
      color: #4D5E80;
  }
  
  .adminaddproductmain_col2_row2_inner_row4 {
    padding-top: 35px;
  }
  
  .adminaddproductmain_col2_row2_inner_row4 label {
    color: #6b7a99;
  }
  
  .main_isselected{
    color: white;
    /* border-bottom: violet; */
    text-decoration: underline;
  }
  .adminaddproductmain_col1_row3_row4{
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    gap: 10px;
  }
  .adminaddproductmain_col1_row3_row4_input input{
    width: 95%;
    padding: 10px;
    border: none;
    outline: none;
  }
  .adminaddproductmain_col1_row3_row1{
    display: flex;
    flex-direction: row;
    padding-top: 10px;
  }
  .adminproductmain_col2_row1_outer_row2_col1_row_select select{
    width:100%;
    }
   ::placeholder{
    color: #d8deea;
    /* opacity: 1; */
  }
  /* .productmain_select{
    color: #6b7a99;
  } */
  /* .productmain_select{
    color: black;
  } */


  @media only screen and (max-width:500px){
    .adminproductmain{
      display: flex;
      flex-direction: column;
      /* background-color: yellow; */
    }
    .adminproductmain_col1{
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      width: 85%;
      margin-left:0px;
        /* background-color: yellow; */
    }
    .adminproductmain_col2{
      margin-left: 10px;
      display: contents;
  
    }
    .adminproductmain_col1_row2_col1_row1{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
    }
  }

  @media only screen and (max-width:350px){
    .adminproductmain_col2{
      margin-left: 0px;
      width: 100%;
    }
    .adminproductmain_col2_row1_outer_row2_col1{
      column-gap: 0px;
    }
    .adminproductmain_col2_row1_outer_row2_col1_row input{
      width: 75%;
    }
    .adminproductmain{
      padding: 0;
    }
    .adminproductmain_col2_row1_outer_row2_col1_row_select input{
      width:83px;
    }
  }

  /********************toast/snackbar**************/

/* The snackbar - position it at the bottom and in the middle of the screen */

#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #333; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
