.adminrelated {
    background-color: #FAFBFC;
    padding: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: .75rem;
    width: 58%;
    padding-right: 40px;
}

.adminrelated_inner {

    padding: 10px;
    width: 100%;

}

.adminrelated_inner_row1 {
    justify-content: space-between;
    display: flex;
    padding-top: 10px;
    padding: 10px;
    margin-bottom: 15px;
}

.adminrelated_inner_row1 label {
    color: #6B7A99;
}
.singleadminrelated_inner_label1{
    width:15% ;
}

.singleadminrelated_inner_img {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 35%;
}

.adminrelated_inner_row3 button {
    padding: 10px 15px;
    border-radius: 20px;
    border: 2px solid #F2F3F5;
    outline: none;

    background-color: #FAFBFC;
    color: #6B7A99;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: .75rem;
}

.singleadminrelated {
    background-color: white;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
}

.adminrelated_inner_header {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.8rem;
    font-weight: 550;
    display: flex;
    flex-direction: row;
    color: #6B7A99;
    justify-content: space-between;
    padding-bottom: 20px;
    width: 95%;
}
.adminrelated_inner_row3{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.8rem;
    font-weight: 550;
    color: #6B7A99;
    margin-top: 30px;
    justify-content: center;
    display: flex;
}
.adminrelated_inner_row3 span {
   padding-left: 10px;
   padding-right: 15px;
}
.adminrelated_inner_header4{
    width:14% ;
    display: flex;
    justify-content: center;
    /* padding-left: 10px; */

}

.adminrelated_inner_header1 {
    width: 35%;
    display: flex;
    justify-content: center;
}

.adminrelated_inner_header2 {
    width: 27%;
    display: flex;
    justify-content: center;
}

.adminrelated_inner_header3 {
    width: 25%;
    display: flex;
    justify-content: center;
}

.singleadminrelated_inner {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 20px 35px;
    /* gap: 110px; */
}

.singleadminrelated_inner label {
    color: #6B7A99;
}


.singleadminrelated_inner_label2{
    width: 25%;
    display: flex;
    justify-content: center;
}

.singleadminrelated_inner_label3{
    width: 25%;
    display: flex;
    justify-content: center;
} 

@media only screen and (max-width:500px){
    .adminrelated {
        width: 85%;
        border-radius: 5px;
    }
    .singleadminrelated_inner{
        gap:0;
        padding: 20px 15px;
    }
    .singleadminrelated_inner_label3{
        width: 28%;
    }
}