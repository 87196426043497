.singleAdmintasksheet_col1_circle{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color:#DCD9FF; */
    /* background-color: #b8837f; */
    background-color: white;
    font-size: 20px;
    /* color: white; */
}

.singleAdmintasksheet{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 5%;
    border: none;
    width: 100%;
    /* background-color: #FAFAFB; */
    border-radius: 3px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.admintasksheet_row1{
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.singleAdmintasksheet_col2_row1{
    font-weight: 650;
    font-size: .9rem;
    color: white;
}
.singleAdmintasksheet_col2_row2{
    font-size: .6rem;
    color: #AEAEAE;
}
.admintasksheet_row1_col1{
    background-color: rgb(213, 67, 67);
    width: 13%;
    padding: 20px;
    border-radius: 5px;
}
.singleAdmintasksheet_col1_icon1{
    color:rgb(213, 67, 67) ;
}
.admintasksheet_row1_col2{
    background-color: rgb(236, 236, 49);
    width: 13%;
    padding: 20px;
    border-radius: 5px;
}
.singleAdmintasksheet_col1_icon2{
    color:rgb(236, 236, 49) ;
}
.admintasksheet_row1_col3{
    background-color: rgb(42, 79, 132);
    width: 13%;
    padding: 20px;
    border-radius: 5px;
}
.singleAdmintasksheet_col1_icon3{
    color:rgb(42, 79, 132) ;
}
.admintasksheet_row1_col4{
    background-color: rgb(49, 155, 49);
    width: 13%;
    padding: 20px;
    border-radius: 5px;
}
.singleAdmintasksheet_col1_icon4{
    color:rgb(49, 155, 49) ;
}