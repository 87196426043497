.adminuserprofiledetails {
    display: flex;
    flex-direction: column;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.8rem;
    font-weight: bold;
    color: #74829F;
    background-color: #F7F8FA;
    width: fit-content;
    padding: 30px;
    gap: 30px;
    border-radius: 8px;
    width: 100%;
}
.adminuserprofiledetails_top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.adminuserprofiledetails_body {
    display: flex;
    flex-direction: row;
justify-content: space-around;
}

.adminprofiledetailsheader_list {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    gap: 25px;
    /* margin-left: 25; */
}

.adminprofiledetails_list_l2 input {
    border: none;
    outline: none;
    color: #4E5E80;
    width: 80%;
    background-color: white;
    padding: 10px;
    border: 1px solid white;
}

.adminprofiledetails_list_l2 input::placeholder {
    color: #4E5E80;
}

.adminprofiledetails_list_l4 input {
    border: none;
    outline: none;
    color: #4E5E80;
    width: 80%;
    background-color: white;
    padding: 10px;
}

.adminprofiledetails_list_l4 input::placeholder {
    color: #4E5E80;
}

@media only screen and (max-width:500px){
    .adminuserprofiledetails{
        width: 130%;
        margin-left: -100px;
    }
}