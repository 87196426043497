.adminslider_outer {
    display: flex;
    width: 100%;
}
.adminslider_loader{
    position: absolute;
    margin-top: 200px;
    margin-left: -30px;
}

.adminslider {
    display: flex;
    flex-direction: column;
    width: 90%;
}

.adminslider_cont {
    display: flex;
    justify-content: center;
    width: 100%;
    /* background-color: yellow; */
}

.adminslider_cont_inner {
    display: flex;
    flex-direction: column;
    /* padding: 50px; */
    color: #6b7a99;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.7rem;
    font-weight: 500;
    width: 100%;
}

.adminslider_cont_inner_select {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 50px;
    width: 100%;
}


.adminslider_cont_inner_select_slider {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 60px 150px;
    background-color: #F5F5F5;
    color: #6b7a99;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.9rem;
    font-weight: 500;
    border-radius: 5px;
    margin-right: 20px;
}

.adminslider_cont_inner_review {
    padding-left: 50px;
}

.adminslider_cont_inner_review_slider {
    padding: 10px;
    border: 1px solid #f5f6f7;
    border-radius: 5px;
    /* margin-right: 219px; */
}

.adminslider_cont_inner_review_slider1_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #6b7a99;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.9rem;
    font-weight: 500;
    gap: 20px;
}

.adminslider_cont {
    box-shadow: 0px 4px 15px rgb(0 0 0 / 10%);
}

.adminslider_cont_inner_select_slider_label {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.adminslider_cont_inner_review_slider1_body_images {
    display: flex;
}

.adminslider_cont_inner_select_cont {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* background-color: gainsboro; */
}

.adminslider_cont_inner_select_slider1_button,
.adminslider_cont_inner_select_slider2_button,
.adminslider_cont_inner_select_slider3_button,
.adminslider_cont_inner_select_slider4_button {
    display: flex;
    justify-content: center;
    width: 100%;

}

.wrapper {
    display: inline-block;
    position: relative;
    /* background-color: yellow; */
}

.btnimg {
    display: flex;
    background: blue;
    pointer-events: none;
    width: 50px;
    height: 50px;
    opacity: 1;
    line-height: 90px;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
}

input[type=file] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

@media only screen and (max-width:500px) {
    .adminslider_cont_inner_select_cont {
        display: flex;
        flex-direction: column;
        margin-left: -25px;
        row-gap: 10px;
    }

    .adminslider_cont_inner_select {
        padding-right: 0px;
    }

    .adminslider_cont_inner_select_slider {
        padding: 30px 80px;
    }

    .adminslider {
        width: 100%;
    }


    .adminslider_cont_inner_select_slider1_button,
    .adminslider_cont_inner_select_slider2_button,
    .adminslider_cont_inner_select_slider3_button,
    .adminslider_cont_inner_select_slider4_button {
        display: flex;
        justify-content: center;
    }

    .adminslider_cont_inner_select_slider_label {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .adminslider_cont_inner_review {
        /* background-color: yellow; */
        margin-left: -50px;
    }

    .adminslider_cont_inner_review_slider {
        display: flex;
        justify-content: left;
        padding-left: 0;
        /* background-color: yellow; */
        width: 90%;
    }
}