.admingoldratepage{
    width: 100%;
}
.admingoldratepage_col2_col1{
    display: flex;
}
.admingoldratepage_col2{
    display:flex;
    width: 100%;
    flex-direction: row;
}
.admingoldratepage_col2_col2{
    width: 100%;
    display: flex;
    justify-content: center;
    /* background-color:yellow ; */
}