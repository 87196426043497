.loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
margin-left: 40%; 
margin-top: 10%;
 }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .hidden {
    display: none;
  }
  
  /* .loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
  } */
  
  /* .spinner {
      width: 64px;
      height: 64px;
      border: 8px solid;
      border-color: #3d5af1 transparent #3d5af1 transparent;
      border-radius: 50%;
      animation: spin-anim 1.2s linear infinite;
  }
  
  @keyframes spin-anim {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
  } */
  