.Admincategorytitle{
    width: 97%;
    background-color: #FAFBFC;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction:row;
    padding:0 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
   border-bottom: 1px solid #EDEFF2;
   font-size: .8rem;
   font-weight: bold;
}
.AdminTitle_Titlemenu_col1{
    /* width: 40%; */
   
    display: flex;
    justify-content:center;
    /* margin-bottom: 15px; */
}
.AdminTitle_Titmenu_col1_col1{
    /* width: 20%; */
    display: flex;
    justify-content:left;
   

}
.AdminTitle_Titmenu_col1_col1 label{
    width: 10%;
    width: 40px;
    height: 40px;
    /* background-color: rgb(213, 42, 71); */
    background-color: #FFFFFF;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.AdminTitle_Titmenu_col1_col2{
    /* width:30% ; */
    display: flex;
    justify-content: left;
    align-items: center;
    margin-left: 15px;
    
}
.AdminTitle_Titmenu_col1_col2 label{
    width: 100%;
    color:#6B7A99;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    /* font-style: normal; */
    font-weight: 500;
    font-size: .9rem;
    /* line-height: 21.6px; */
    display: flex;
    justify-content: left;
    text-align: center;
}

.AdminTitle_Titlemenu_col2{
    /* width:60% ; */
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: row;
    /* margin-top: 10px; */

}
.AdminTitle_Titmenu_col2_co1{
    padding: 21px 0;
}

.AdminTitle_Titmenu_col2_co1 label{
    color: #7D8FB3;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: .8rem;
    justify-content:space-around ;
    padding: 21px 0;
    margin-left: 10px;
    margin-right: 10px;
}
.AdminTitle_Titmenu_col2_co1 :hover {
    color:#8833FF;
    border-bottom: 2px solid #8833FF;
}
.Adminordertitle_Titlemenu_col3{
    /* width: 50%; */
    display: flex;
    /* display: none; */
    align-items: center;
    justify-content:center;
    align-content: center;
    margin-bottom: 15px;
}
.AdminTitle_Titmenu_col3_iconbutton{
    /* width: 30%; */
    background-color:#8833FF ;
    border: none;
    border-radius: 25px;
    display: flex;
    justify-content:center;
    flex-direction: row;
    text-align: center;
    align-items: center;
    margin-top: 15px;
    padding: 8px;
    /* display: none; */
}
.AdminTitle_Titmenu_col3_iconbutton button{
   
    background-color:#8833FF ;
    border:  none;
    outline: none;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    /* font-style: normal; */
    font-weight:550;
    font-size: .7rem;
    /* line-height: 21.6px; */ 
}

/***********************************toggle switch************************/
.Adminordertitle_Titlemenu_col3_toggle{
    margin-right: 20px;
}
.container {
    text-align: center;
    }
    .toggle-switch {
    position: relative;
    width: 68px;
    display: inline-block;
    text-align: left;
    top: 10px;
    }
    .checkbox {
    display: none;
    }
    .label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    }
    .inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    }
    .inner:before,
    .inner:after {
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    /* line-height: 36px; */
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
    }
    .inner:before {
    content: "";
    /* padding-left: 15px; */
    background-color: #8833FF;
    color: #fff;
    }
    .inner:after  {
    content: "";
    padding-right: 15px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
    }
    .switch {
    display: block;
    width: 20px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
    }
    .checkbox:checked + .label .inner {
    margin-left: 0;
    }
    .checkbox:checked + .label .switch {
    right: 0px;
    }

    @media only screen and (max-width:500px){
        .Admincategorytitle{
            width: 100%;
            /* background-color: yellow; */
        }
        .Adminordertitle_Titlemenu_col3{
            padding-right: 5px;
        }
        .AdminTitle_Titlemenu_col1{
            margin-left: -10px;
        }
    }
    