.adminprofileeditpage_body{
    display: flex;
    flex-direction: row;
}
.adminprofileeditpage_body_right{
    margin-left: 100px;
    margin-top: 80px;
    margin-bottom: 5%;
    width: 100%;
}
.adminprofileeditpage_body_right_title{
    width: 90%;
}
.adminprofileeditpage_body_right_row_r1{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.adminprofileeditpage_body_right_row_r2{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.adminprofileeditpage_body__left{
    display: contents;
}
.adminprofileeditpage{
    display: flex;
    flex-direction: column;
}
.adminprofileeditpage_body_right_row{
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.adminprofileeditpage_body_right_row_r1_userprofile{
   margin-left: 150px;
}
