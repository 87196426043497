.wrapper{
    display:inline-block;
    position:relative;
  }
  
  .btnimg{
    display: flex;
    background:blue;
    pointer-events:none;
    width:50px;
    height:50px;
    opacity:1;
    line-height:90px;
    align-items:center;
    border-radius: 50%;
    justify-content: center;
  }
  
  input[type=file]{
    opacity:0;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
  }