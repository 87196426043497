.adminupload_outer{
    /* padding: 20px; */
    width: 100%;
    justify-content: center;
    /* display: flex; */
    flex-direction: column;
    border-radius: 3px;
    margin-bottom: 30px;
}
.adminupload_3{
 /* width: 90%; */
 margin: 50px;

}
.adminupload{
    margin-top: 50px;
    width: 100%;
}
.adminupload_row1{
    /* width: 100%; */
    display: flex;
}
.adminupload_row2{
    display: flex;
}
.AdminUploadUrl_body{
    background-color: #F5F5F5;
    /* width: 350px;
    height: 350px; */
    border-radius: 15px;
    padding-bottom: 100px;
    width: 48%;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 20px;

}
.AdminUploadUrl_l1{
    display: flex;
   padding-top: 55px;
   padding-bottom: 70px;
    justify-content: center;
    align-items: center;
    color: #8092B5;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: .9rem;
    font-weight: 550;
    /* letter-spacing: -1px; */
}

.AdminUploadUrl_l2{
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    background-color: white;
    height: 60px;
    width: 300PX;   
    /* border: blue; */
    border-style: groove;
    border-radius: 30px;
    border: none;
    outline: none;

} 

.AdminUploadUrl_textbox{
    color: #8092B5;
    justify-content: center;
}

.AdminUploadUrl_textbox input{
    border: none;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: .7rem;
    font-weight: bold;
    color: #8092B5;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: none;
    padding-left: 27px;
    width: max-content;
    margin-left: 10px;
}


.AdminUploadUrl_button{
background-color: white;
padding-left: 20px;
}

.AdminUploadUrl_button button{
background-color: white;
    border: none;
    justify-content: center;
    align-items: center;
}

.upload_btn{
    cursor: pointer;
    display: none;

}
.AdminImages_outer_label{
    width: 100%;
    display: flex;
    justify-content:space-between;
    margin-right: 100px;
    /* display: hidden; */
    color: white;
    padding: 0;
}

.uploadbutton{
    height: 50px;
    width: 50px;
}
.AdminImages_start{
    border: 3px solid #f5f6f7;
    margin-top: 30px;
    border-radius: 10px;
    width: 46%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    padding-bottom: -20px;
    /* margin-top: -20px; */
    /* height: 90vh; */
}
.AdminImages_outer_label label{
    color: #6B7A99;
    font-size: .8rem;
    font-weight: 550;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.wrapper{
    display:inline-block;
    position:relative;
  }
  
  .btnimg{
    display: flex;
    background:blue;
    pointer-events:none;
    width:50px;
    height:50px;
    opacity:1;
    line-height:90px;
    align-items:center;
    border-radius: 50%;
    justify-content: center;
  }
  
  input[type=file]{
    opacity:0;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
  }

  @media only screen and (max-width:500px){
    .AdminUploadFile_text{
        padding-top: 0px;
        padding-bottom: 30px;
      }
      .AdminUploadFile_body{
        padding-top: 0px;
        padding-bottom: 30px;
      }
      .AdminVideo_continer{
        width:80%
      }
      .AdminVideo_inner_cont_row1_col2{
        justify-content: right;
      }
      .AdminVideo_outer_video img{
        width: 160px;
      }
      .AdminVideo_outer{
        margin-right: 10px;
      }
      .AdminImages_start{
        width: 36%;
      }
      .AdminImages_inn_image img{
        width: 50px;
      }
      .AdminImages_outer {
        display: flex;
        flex-direction: column;
      }
  }

  @media only screen and (max-width:350px){
    .AdminImages_outer{
      margin-left: 5px;
    }
    .AdminImages_inn_image img{
      width: 50px;
      height: 50px;
    }
    .AdminImages_inner{
      margin-left: 0;
    }
    .AdminImages_start {
      width: 36%;
    }
    .AdminVideo_outer_video img{
        width: 100px;
      }
  
  }