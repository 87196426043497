.adminuserlocationdetails {
    display: flex;
    flex-direction: column;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.8rem;
    font-weight: bold;
    color: #74829F;
    background-color: #F7F8FA;
    width: fit-content;
    padding: 30px;
    gap: 30px;
    border-radius: 8px;
    width: 100%;
}
.adminuserlocationdetails_top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.adminuserlocationdetails_body {
    display: flex;
    flex-direction: row;

}

.adminlocationdetailsheader_list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.adminlocationdetails_list_l2 input {
    border: none;
    outline: none;
    color: #4E5E80;
    width: 80%;
    background-color: white;
    padding: 10px;
    border: 1px solid white;
}

.adminlocationdetails_list_l2 input::placeholder {
    color: #4E5E80;
}

.adminlocationdetails_list_l4 input {
    border: none;
    outline: none;
    color: #4E5E80;
    width: 80%;
    background-color: white;
    padding: 10px;
}

.adminlocationdetails_list_l4 input::placeholder {
    color: #4E5E80;
}
.adminuserlocationdetails_body{
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.adminuserlocationdetails_body_col1{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
 
}
.adminuserlocationdetails_body_col2{
    display: flex;
    flex-direction: row;
    padding-left: 35px;
}
.adminuserlocationdetails_body_col2_inner{
    padding-left: 67px;
}

@media only screen and (max-width:500px){
    .adminuserlocationdetails{
        width: 130%;
        margin-left: -100px;
    }
    .adminlocationdetailsheader_list {
        gap: 25px;
    }
}