.AdminImages_outer {
  width: 100%;
  /* background-color: rgb(198, 198, 231); */
  /* background-color: white; */
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  justify-content: center;
  align-items: center;
  /* margin-top: 15px; */
  /* background-color: white; */
  /* background-attachment: fixed; */
  /* padding: 20px; */
}
.AdminImages_inner {
  /* width:30%; */
  background-color: white;
  border: 3px solid #f5f6f7;
  border-radius: 6px;
  /* display: flex; */
  /* display:table; */
  /* display: inline-table; */
  justify-content: center;
  margin: 10px;
  margin-left: 10px;
  /* padding: 10px; */
  margin-top: 5px;
  /* margin-bottom: 10px; */
}
.AdminImages_inn_image {
  width: 100%;
  display:flex;
  justify-content: center;
  align-items: center;

}
.AdminImages_inner img {
  width: 30%;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  /* background-color: #f5f6f7; */
}

.AdminImages_outer_row1,
.AdminImages_outer_row2 {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width:500px){
  .AdminImages_outer{
    margin-left: 5px;
  }
  .AdminImages_inn_image img{
    width: 50px;
    height: 50px;
  }
  .AdminImages_inner{
    margin-left: 0;
  }
  

}

@media only screen and (max-width:350px){
  .AdminImages_outer{
    margin-left: 5px;
  }
  .AdminImages_inn_image img{
    width: 50px;
    height: 50px;
  }
  .AdminImages_inner{
    margin-left: 0;
  }
  .AdminImages_start {
    width: 36%;
  }
  .AdminImages_outer {
    display: flex;
    flex-direction: column;
  }

}