.admincategorylist{
/* width: 40%; */
background-color: white;
padding: 10px;
}
.admincategorylist_row1{
    display: flex;
    flex-direction: row;
    padding: 20px 0px;
    background-color: white;
    /* justify-content: center; */
}

.admincategorylist_row1_col1{
    width: 2.5%;
    justify-content: center;
    display: flex;
    padding-left: 85px;
}
.admincategorylist_row1_col2{
    width: 40%;
    justify-content: center;
    display: flex;
    /* padding-left: 20px; */
}
.admincategorylist_row1_col4{
    width:40%;
    justify-content: center;
    display: flex;
}
.admincategorylist_row1_col3{
    width: 17.5%;
    justify-content: center;
    display: flex;
}
.admincategorylist_row2{
    background-color: #FAFBFC;
    display: flex;
    /* justify-content: space-between; */
    /* width: 85%; */
    padding: 20px;
    /* padding-right: 60px; */
    margin-bottom: 10px;
    border-radius: 3px;
}
.admincategorylist_row2:hover{
    background-color: rgb(207, 178, 234);
    color: white;
}
.admincategorylist_row2_col1{
    width: 18%;
    justify-content: left;
    display: flex;
    padding-left: 67px;
}
.admincategorylist_row2_col2{
    width: 40%;
    display: flex;
    justify-content: left;
    padding-left: 10px;
}
.admincategorylist_row2_col3{
    width: 25%;
    display: flex;
    justify-content: left;
}
.singleadminlist_row_edit{
    width: 6%;
    display: flex;
    justify-content: left;
}
.admincategorylist_row1 label{
    color: #74829F;
    font-size: .8rem;
    font-weight: 550;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.admincategorylist_row2{
    color: #74829F;
    font-size: .7rem;
    font-weight: 550;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}