.admingoldrate {
  display: flex;
   width: 100%;
   color: #6b7a99;
   font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
   font-size: 0.7rem;
   font-weight: 500;
}
.admingoldrate_loader{
  position: absolute;
  margin-left: -18%;
  margin-top: 300px;
}

.admingoldrate_inner {
  width: 80%;
  /* width: 120%; */
  padding: 50px;
  /* box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); */
  border-radius: 3px;
  padding-bottom: 30px;
 
}
.admingoldrate_row2 {
  /* background-color: yellow; */
  padding: 20px;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 10%);
}
/* .admingoldrate_row2_inner {
  /* margin: 0%; */
  /* padding: 164px 130px; */

.admingoldrate_row2_inner_row {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  width: 48%;
  padding-left: -20px;
  gap: 10px;
  padding: 5px;
}
.admingoldrate_col_row2_inner {
  display: flex;
  align-items: center;
  width: 48%;
}
.admingoldrate_row2_inner_row span {
  padding: 0px 30px;
  color: #8092b5;
  font-size: 0.9rem;
}
.admingoldrate_row2_inner_row label {
  /* color: #8092b5; */
  font-size: 0.7rem;
  font-weight: 500;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.admingoldrate_row2_inner_row_input {
  /* border: 2px solid silver;
  border-radius: 5px; */
}
.admingoldrate_row2_inner_row_input input {
  outline: none;
  border: none;
 
  width: 92%;
  padding: 9px;
 
  /* border-color: 2px solied silver; */
}
.admingoldrate_row2_row{
    display: flex;
    /* flex-direction: column; */
    width: 90%;
    margin-top: 40px;
    margin-bottom: 40px;
   
}

.admingoldrate_row2_row_row{
    background-color: #FAFBFC;
    /* width: 23%; */
    width: 48%;
    /* padding: 20px; */
    /* padding-left: 35px; */
    padding-right: 40px;
    padding-bottom: 30px;
    border-radius: 5px;
    margin-right: 20px;
    padding: 20px;
}
.admingoldrate_row2_row_col2{
    width: 48%;
    display: flex;
    background-color: #FAFBFC;
    padding: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: .75rem;
    /* width: 58%; */
    padding-right: 40px;
}
.admingoldrate_row2_row_col2_inner{
   padding: 10px;
    width: 100%;
    display: flex;
    justify-content: center;

}
.admingoldrate_col_row2_inner_row{
  display: flex;
  justify-content: center;
  background-color: white;
  margin-left: 70px;
  width: 25%;
  padding: 10px;
}
.admingoldrate_row2_inner_title{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
}
.admingoldrate_row2_inner{
  height: 350px;
}

@media only screen and (max-width:500px){
  .admingoldrate_row2_row{
    display: flex;
    flex-direction: column;
  }
  .admingoldrate_row2_row_row{
    width: 90%;
  }
  .admingoldrate_row2_row_col2{
    display: none;
  }
}

@media only screen and (max-width:350px){
  .admingoldrate_row2_inner_row label {
    width: 200%;
  }
}
