.AdminVideo_outer{
    width: 48%;
    /* background-color: rgb(77, 114, 102); */
    /* background-color:#FFFFFF; */
    display: flex;
   margin-right: 20px;
   justify-content: center;
   /* height: 90vh; */
}
.AdminVideo_continer{
   width: 100%;
    /* background-color:#FFFFFF; */
    border: 3px solid #F5F6F7;
    border-radius: 8px;
    display: flex;
   padding: 10px;
   /* padding-bottom: 50px; */
    margin-bottom: 20px;
    margin-top: 30px;
    /* padding-bottom: 40px; */
}
.AdminVideo_inner_cont{

    display: flex;
    /* width: 315px; */
    flex-direction:column;
    /* margin: 25px; */

}
.AdminVideo_outer_video{
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 70px;
}

.AdminVideo_inner_cont_row1{
    width: 100%;
    display: flex;
    flex-direction:row;
    margin-top: 10px;
    justify-content: space-between;

}
.AdminVideo_inner_cont_row1_col1{
   
    display: flex;
    justify-content: left;
    align-items: center;
   
    
}
.AdminVideo_inner_cont_row1_col1 label{
    display: flex;
    justify-content: left;
    color:#6B7A99;
    text-align: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.8rem;
   font-weight: 500;

}

.AdminVideo_inner_cont_row1_col2{

    display: flex;
    justify-content:right;
    /* align-items: center; */
    margin-top: 10px;
    
}

.AdminVideo_inner_cont_row2{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}
.AdminVideo_inner_cont_row2 img{
    width: 20%;
    width: 80px;
    height: 80px;
    padding-top: 50px;
}

@media only screen and (max-width:350px){
    .AdminImages_outer{
      margin-left: 5px;
    }
    .AdminImages_inn_image img{
      width: 50px;
      height: 50px;
    }
    .AdminImages_inner{
      margin-left: 0;
    }
    .AdminImages_start {
      width: 36%;
    }
    .AdminVideo_outer_video img{
        width: 100px;
      }
  
  }