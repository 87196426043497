.admincategorylistpage_row_col2 {
    width: 70%;
    margin-left: 100px;
    margin-top: 50px;
    margin-bottom: 2%;
    background-color: white;
}

.admincategorylistpage_row_col2_inner {
    width: 99.4%;
}

.admincategorylistpage_loader {
    margin-left: -10%;
}

@media only screen and (max-width:500px) {
    .admincategorylistpage_row_col2 {
        width: 88%;
        margin-left: 2px;
    }
    .admincategorylistpage_adminsubtitlelist{
        width: 113%;
        /* display: flex; */
    }
    /* .admincategorylistpage_admincategorylist{
        width: 113%;
        margin-left: -40px;
    } */
}