.adminorderedittitle_cont {
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: center;
}

.adminordertitle_list {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.adminordertitle_list:hover {
    color: #8833FF;

}

.adminordertitle_list:hover .adminordertitle_list_label1 {
    background-color: #8833FF;

}

.adminorderedittitle {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.8rem;
    font-weight: 550;
    color: #74829F;
    background-color: #F7F8FA;
    padding: 40px;
    border-bottom: 1px solid #ededed;
}

.adminordertitle_list_label1 {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #F7F8FA;
    border: 1px solid #d0eefb;
}

.adminordertitle_list_label1 label {
    display: flex;
    padding-left: 6px;
}

