 
.adminorderedit{
    display: flex;
    flex-direction: column;
}
.adminorderedit_row1{
    display: flex;
    flex-direction: row;
    
}
.adminorderedit_row1_col2{
    width: 80%;
    margin-top: 60px;
    margin-left: 75px;
    margin-bottom: 60px;
}
.adminorderedit_row1_col2_row{
    display: flex;
    flex-direction: row;
    padding: 30px;
    gap: 30px;
}
.adminorderedit_row1_col2{
    padding: 40px;
    border-radius: 5px solid grey;
    /* margin-left: 150px;
    margin-top: 50px;  */
}
.adminorderedit_row1_col1{
    display: contents;
}

@media only screen and (max-width:500px){
    .adminorderedit_row1_col2{
        margin-left: 0px;
        /* width: 70%; */
    }
}