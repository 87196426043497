.adminaddsaveitle_Titlemenu{
    background-color: #FAFBFC;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction:row;
    padding: 0 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
   border-bottom: 1px solid #EDEFF2;
}

.adminaddsaveitle_Titmenu_col3_iconbutton:hover,
.adminaddsaveitle_Titmenu_col3_iconbutton:hover .adminaddsaveitle_Titmenu_col3_iconbutton_button{
    background-color:#4a0d9a ;
}

.adminaddsaveitle_Titlemenu_col1{
    /* width: 40%; */
   
    display: flex;
    justify-content:center;
    /* margin-bottom: 15px; */
}
.adminaddsaveitle_Titmenu_col1_col1{
    /* width: 20%; */
    display: flex;
    justify-content:left;
   

}
.adminaddsaveitle_Titmenu_col1_col1 label{
    width: 10%;
    width: 40px;
    height: 40px;
    /* background-color: rgb(213, 42, 71); */
    background-color: #FFFFFF;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.adminaddsaveitle_Titmenu_col1_col2{
    /* width:30% ; */
    display: flex;
    justify-content: left;
    align-items: center;
    margin-left: 15px;
    
}
.adminaddsaveitle_Titmenu_col1_col2 label{
    width: 100%;
    color:#6B7A99;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    /* font-style: normal; */
    font-weight: 500;
    font-size: .9rem;
    /* line-height: 21.6px; */
    display: flex;
    justify-content: left;
    text-align: center;
}

.adminaddsaveitle_Titlemenu_col3{
    /* width: 50%; */
    display: flex;
    justify-content:center;
    align-content: center;
    margin-bottom: 15px;
    align-items: center;
}
.adminaddsaveitle_Titmenu_col3_iconbutton{
    /* width: 30%; */
    background-color:#8833FF ;
    border: none;
    border-radius: 25px;
    display: flex;
    justify-content:center;
    flex-direction: row;
    text-align: center;
    align-items: center;
    margin-top: 15px;
    padding: 8px;
}
.adminaddsaveitle_Titlemenu_col3 button{
   
    background-color:#8833FF ;
    border:  none;
    outline: none;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    /* font-style: normal; */
    font-weight:550;
    font-size: .7rem;
    /* line-height: 21.6px; */  
}

/***********************************toggle switch************************/
.adminaddsaveitle_Titlemenu_col3_toggle{
    margin-right: 20px;
}
.container {
    text-align: center;
    }
    .toggle-switch {
    position: relative;
    width: 68px;
    display: inline-block;
    text-align: left;
    top: 10px;
    }
    .checkbox {
    display: none;
    }
    .label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    }
    .inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    }
    .inner:before,
    .inner:after {
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    /* line-height: 36px; */
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
    }
    .inner:after {
    content: "";
    /* padding-left: 15px; */
    background-color: #bbb;
    color: #fff;
    }
    .inner:before  {
    content: "";
    padding-right: 15px;
    background-color: #8833FF;
    color: #fff;
    text-align: right;
    }
    .switch {
    display: block;
    width: 20px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
    }
    .checkbox:checked + .label .inner {
    margin-left: 0;
    }
    .checkbox:checked + .label .switch {
    right: 0px;
    }
    
    /* @media only screen and (max-width:350px){
        .adminaddsaveitle_Titlemenu_col3_toggle{
            margin-left: 10px;
            margin-right: 4px;
        }
        .adminaddsaveitle_Titlemenu{
            width: 93%;
        }
    } */
    
    /********************toast/snackbar**************/

/* The snackbar - position it at the bottom and in the middle of the screen */


    #snackbar {
        visibility: hidden; /* Hidden by default. Visible on click */
        min-width: 250px; /* Set a default minimum width */
        margin-left: -125px; /* Divide value of min-width by 2 */
        background-color: #333; /* Black background color */
        color: #fff; /* White text color */
        text-align: center; /* Centered text */
        border-radius: 2px; /* Rounded borders */
        padding: 16px; /* Padding */
        position: fixed; /* Sit on top of the screen */
        z-index: 1; /* Add a z-index if needed */
        left: 50%; /* Center the snackbar */
        bottom: 30px; /* 30px from the bottom */
      }
      
      /* Show the snackbar when clicking on a button (class added with JavaScript) */
      #snackbar.show {
        visibility: visible; /* Show the snackbar */
        /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
        However, delay the fade out process for 2.5 seconds */
        -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
        animation: fadein 0.5s, fadeout 0.5s 2.5s;
      }
      
      /* Animations to fade the snackbar in and out */
      @-webkit-keyframes fadein {
        from {
          bottom: 0;
          opacity: 0;
        }
        to {
          bottom: 30px;
          opacity: 1;
        }
      }
      
      @keyframes fadein {
        from {
          bottom: 0;
          opacity: 0;
        }
        to {
          bottom: 30px;
          opacity: 1;
        }
      }
      
      @-webkit-keyframes fadeout {
        from {
          bottom: 30px;
          opacity: 1;
        }
        to {
          bottom: 0;
          opacity: 0;
        }
      }
      
      @keyframes fadeout {
        from {
          bottom: 30px;
          opacity: 1;
        }
        to {
          bottom: 0;
          opacity: 0;
        }
      }
      