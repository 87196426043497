.main_isselected {
  color: #fafbfc;
  text-decoration: underline;
}

/*********uploadbutton css*************/
.wrapper {
  display: inline-block;
  position: relative;
}

.btnimg {
  display: flex;
  background: blue;
  pointer-events: none;
  width: 50px;
  height: 50px;
  opacity: 1;
  line-height: 90px;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
}

input[type=file] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media only screen and (max-width:500px) {
  .AdminUploadFile_text {
    padding-top: 0px;
    padding-bottom: 30px;
  }

  .AdminUploadFile_body {
    padding-top: 0px;
    padding-bottom: 30px;
  }

  .AdminVideo_continer {
    width: 80%
  }

  .AdminVideo_inner_cont_row1_col2 {
    justify-content: right;
  }

  .AdminVideo_outer_video img {
    width: 160px;
  }

  .AdminVideo_outer {
    margin-right: 10px;
  }

  .AdminImages_start {
    width: 36%;
  }

  .AdminImages_inn_image img{
width: 20px;
height: 20px;
  }
  .AdminImages_outer {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width:350px) {
  .AdminUploadFile_text {
    padding-top: 0px;
    padding-bottom: 30px;
  }

  .AdminUploadFile_body {
    padding-top: 0px;
    padding-bottom: 30px;
  }

  .AdminVideo_continer {
    width: 80%
  }

  .AdminVideo_inner_cont_row1_col2 {
    justify-content: right;
  }

  .AdminVideo_outer_video img {
    width: 100px;
  }

  .AdminVideo_outer {
    margin-right: 10px;
  }

  .AdminImages_start {
    width: 36%;
  }

  .AdminImages_inn_image img{
width: 20px;
height: 20px;
  }
  .AdminImages_outer {
    display: flex;
    flex-direction: column;
  }
}


