.adminmenubar {
    /* background-color: #e7e8e9; */
    background-color: #f5f7f9;
    padding: 3px 0;
    width: 100%;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    color: #a2b0d1;
    border-bottom: 1px solid silver;
    /* background-color: #FAFBFC; */
  }
  
  .adminmenubar_ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .adminmenubar_li {
    list-style-type: none;
    width: 100%;
    text-align: center;
    font-weight: bold;
    /* color: rgb(12, 11, 11); */
    color: #a2b0d1;
    margin-bottom: -3px;
    border-bottom: 3px solid #f5f7f9;
  }
  
  .adminmenubar_li:hover {
    color: #646f86;
    display: flex;
    border-bottom: 3px solid #646f86;
  }
  .adminmenubar_title {
    width: 100%;
    padding: 8px 0;
    justify-content: center;
    display: flex;
    /* background-color: yellow; */
    /* flex-direction: row; */
  }

  @media only screen and (max-width:500px){
    .adminmenubar_ul {
      /* margin: 0;
      padding: 0; */
      display: flex;
      flex-direction: column;
      /* justify-content: space-between; */
    }
  }
    