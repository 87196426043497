.admincategoryeditpage_col2_col2{
    display: flex;
    flex-direction: column;
}
.admineditcategorypage{
    width: 100%;
}
.admineditcategorypage_col2_col1{
    display: flex;
}
.admineditcategorypage_col2{
    display:flex;
    width: 100%;
    flex-direction: row;
}
.admineditcategorypage_col2_col2{
    width: 100%;
    display: flex;
    justify-content: center;
    /* background-color:yellow ; */
}