.AdminUploadFile_body{
    background-color: #F5F5F5;
    /* width: 350px;
    height: 350px; */
    border-radius: 15px;
    width: 48%;
    padding-bottom: 100px;
    padding-top: 50px;
    margin-right: 20px;
}
.AdminUploadFile_text{
    display: flex;
   padding-top: 60px;
   padding-bottom: 70px;
    justify-content: center;
    align-items: center;
    color: #8092B5;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: .9rem;
    font-weight:550;
    /* letter-spacing: -1px; */
}
.AdminUploadFile_button{
    display: flex;
    /* background-color: #8833FF; */
    border: none;
    justify-content: center; 
    align-items: center;
    
}
.AdminUploadFile_button button{
    display: flex;
    padding: 30px;
background-color:#8833FF;
border-radius: 50%;
border: none;

}