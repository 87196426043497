.AdminTitle_Titlemenu{
    width: 97%;
    background-color: #FAFBFC;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction:row;
    padding: 0 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
   border-bottom: 1px solid #EDEFF2;
   font-size: .8rem;
   font-weight: bold;
}
.AdminTitle_Titlemenu_col1{
    /* width: 40%; */
   
    display: flex;
    justify-content:center;
    /* margin-bottom: 15px; */
}
.AdminTitle_Titmenu_col1_col1{
    /* width: 20%; */
    display: flex;
    justify-content:left;
   

}
.AdminTitle_Titmenu_col1_col1 label{
    width: 10%;
    width: 40px;
    height: 40px;
    /* background-color: rgb(213, 42, 71); */
    background-color: #FFFFFF;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.AdminTitle_Titmenu_col1_col2{
    /* width:30% ; */
    display: flex;
    justify-content: left;
    align-items: center;
    margin-left: 15px;
    
}
.AdminTitle_Titmenu_col1_col2 label{
    width: 100%;
    color:#6B7A99;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    /* font-style: normal; */
    font-weight: 500;
    font-size: .9rem;
    /* line-height: 21.6px; */
    display: flex;
    justify-content: left;
    text-align: center;
}

.AdminTitle_Titlemenu_col2{
    /* width:60% ; */
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: row;
    /* margin-top: 10px; */

}
.AdminTitle_Titmenu_col2_co1{
    padding: 21px 0;
}

.AdminTitle_Titmenu_col2_co1 label{
    color: #7D8FB3;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: .8rem;
    justify-content:space-around ;
    padding: 21px 0;
    margin-left: 10px;
    margin-right: 10px;
}
.AdminTitle_Titmenu_col2_co1 :hover {
    color:#8833FF;
    border-bottom: 2px solid #8833FF;
}
.AdminTitle_Titlemenu_col3{
    /* width: 50%; */
    display: flex;
    /* display: none; */
    justify-content:center;
    align-content: center;
    margin-bottom: 15px;
}
.AdminTitle_Titmenu_col3_iconbutton{
    /* width: 30%; */
    background-color:#8833FF ;
    border: none;
    border-radius: 25px;
    display: flex;
    justify-content:center;
    flex-direction: row;
    text-align: center;
    align-items: center;
    margin-top: 15px;
    padding: 8px;
    /* display: none; */
}
.AdminTitle_Titmenu_col3_iconbutton:hover,
.AdminTitle_Titmenu_col3_iconbutton:hover .AdminTitle_Titlemenu_col3_button{
    background-color:#4a0d9a ;
}
.AdminTitle_Titlemenu_col3 button{
   
    background-color:#8833FF ;
    border:  none;
    outline: none;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    /* font-style: normal; */
    font-weight:550;
    font-size: .7rem;
    /* line-height: 21.6px; */  
}

.Header_r2_col3 {
    /* display: none; */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  .Header_r2_col3 div {
    background-color: #8833FF;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Header_r2_col3 input {
    border-width: 1px;
    border-color: #cdbfe1;
    border-style: solid;
    padding: 5px 10px;
    outline: none;
  }
  
  .Header_r2_col3 img {
    padding: 0 8px;
  }

@media only screen and (max-width:500px){
    .AdminTitle_Titlemenu{
        width: 50%;
    }
    .AdminTitle_Titmenu_col3_iconbutton{
        margin-right: -20px;
    }
    .AdminTitle_Titlemenu_col3{
        margin-right: -120px;
        padding: 0;
    }
}