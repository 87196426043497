.adminsubtitle {
    /* display: flex; */
    flex-direction: row;
    border-bottom: 1px solid #F5F6F7;
    background-color: white;
    width: auto;
    /* height: 20%; */
    padding: 10px 30px;
    /* padding: 0 20px; */
}
.adminsubtitle_inner{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.adminsubtitle_center {
    /* width: 60%; */
    display: flex;
    flex-direction: row;
   font-size: .9rem;
    /* padding-left: 50px; */
    color:  #C3CAD9;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:500;
    background-color:   #F6F5FF;
    /* margin-left: 50px; */
}



.adminsubtitle_center_list {
    display: flex;
    flex-direction: row;
    border: none;
    
    outline: none;
    background-color: white;
    /* padding: 10px; */
    margin: 2px;
    gap: 20px;
    padding: 5px;

}
.adminsubtitle_center_list1{
    display: flex;
    flex-direction: row;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    outline: none;
    background-color: white;
    /* padding: 10px; */
    margin: 2px;
    gap: 20px;
    padding: 5px;

}
.adminsubtitle_center_list2{
    display: flex;
    flex-direction: row;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    outline: none;
    background-color: white;
    /* padding: 10px; */
    margin: 2px;
    gap: 20px;
    padding: 5px;
}

/* 
.adminsubtitle_right {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 50px;
} */

.adminsubtitle_right{
    /* width: 20%; */
    display: flex;
    flex-direction: row;
    color:  #C3CAD9;
    Font-family: "segoe ui";
    font-weight: 700;
    background-color:   #F6F5FF;
}
.adminsubtitle_center_list1 label,
.adminsubtitle_center_list label,
.adminsubtitle_center_list2 label{
    font-size: .8rem;
}
.adminsubtitle_right_list1{
    display: flex;
    flex-direction: row;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    outline: none;
    background-color: white;
    /* padding: 10px; */
    margin: 2px;
    color: #BABDE0;
    padding: 5px;

}
.adminsubtitle_right_list2{
    display: flex;
    flex-direction: row;
    border: none;
    color: #BABDE0;
    outline: none;
    background-color: white;
    /* padding: 10px; */
    margin: 2px;
  
    padding: 5px;
}
/* .adminsubtitle_right_list3 label {
   font-size: .9rem;
} */

.adminsubtitle_right_list3{
    display: flex;
    flex-direction: row;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    outline: none;
    background-color: white;
    /* padding: 10px; */
    margin: 2px;
    color: #BABDE0;
    padding: 5px;
}
.adminsubtitle_left {
    /* width: 20%; */
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding-right: 50px; */
    /* padding-left: 30px; */
}

.adminsubtitle_left_icon {
    background-color: #CED2DE;
    border-radius: 50%;
    width: 20px;
    height: 20px;



}

.adminsubtitle_left_label label {
    color: #BABDE0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: .8rem;
    padding-left: 10px;
}