.adminordereditcom_outer {
    width: 100%;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}

.adminbasicinformation_loader{
    position: absolute;
    margin-left: 33%;
    margin-top: 200px;
}

.adminordereditcom {
    /* background-color: gray; */
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.adminordereditcom_row1 {
    /* display: flex; */
    width: 100%;
}

.adminordereditcom_row2 {
    width: 100%;
}

.adminordereditcom_row3 {
    display: flex;
    width: 90%;
    margin-top: 40px;
    margin-bottom: 40px;
}

.Adminbasicinformation_outer {
    background-color: #FAFBFC;
    /* width: 23%; */
    width: 40%;
    padding: 20px;
    padding-left: 35px;
    padding-right: 40px;
    padding-bottom: 30px;
    border-radius: 5px;
    margin-right: 20px;
}

.Adminbasicinformation_outer_row1 {
    justify-content: space-between;
    display: flex;
    padding-top: 15px;
    padding-bottom: 30px;
    font-size: .75rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    /* padding-left: 10px; */
}

.Adminbasicinformation_outer_row2_row1 input {
    outline: none;
    border: none;
    background-color: #FAFBFC;
}

.Adminbasicinformation_outer_row1 {
    color: #6B7A99;
}

.Adminbasicinformation_outer_row2_row1 {
    border-bottom: 3px solid #F2F3F5;
    padding: 15px 10px;
    font-size: .7rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    /* background-color: aliceblue; */
    display: flex;
}

.Adminbasicinformation_outer_row2_row1 select {
    border: none;
    outline: none;
    color: #6B7A99;
    background-color: #FAFBFC;
    width: 50%;
}

.Adminbasicinformation_outer_row2_row2 {
    padding: 15px 10px;
    font-size: .7rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    /* background-color: ; */
    display: flex;
}

/* .Adminbasicinformation_outer_row2_row1_label2 */

.Adminbasicinformation_outer_row2_row1_label1 {
    width: 50%;
    color: #ADB8CC;
}

.Adminbasicinformation_outer_row2_row1_label2 {
    width: 50%;
    color: #4D5E80;
    display: flex;
}

.Adminbasicinformation_outer_row2_row3 {
    margin-top: 30px;
    padding: 10px;
    border-radius: 3px;
    background-color: #FFFFFF;
    justify-content: space-between;
    display: none;
    align-items: center;
    font-size: .7rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
}

.Adminbasicinformation_outer_row2_row3 label {
    color: #6B7A99;
}

.Adminbasicinformation_outer_row2_row3 button {
    padding: 10px 15px;
    border-radius: 5px;
    border: none;
    outline: none;
    color: white;
    background-color: #8833FF;
    font-size: .7rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
}

.Adminbasicinformation_outer_row2_row3 input {
    border: none;
    outline: none;
}

.AdminTitle_Titlemenu_col2 {
    display: none;
}

@media only screen and (max-width:500px) {
    .adminordereditcom_outer {
        width: 110%;
    }

    .AdminTitle_Titlemenu {
        display: flex;
        width: 70%;
        margin-left: 0px;
    }

    .AdminTitle_Titlemenu {
        width: 91%;
    }

    .adminordereditcom_row3 {
        display: flex;
        flex-direction: column;
    }

    .AdminTitle_Titlemenu_col3 {
        padding-left: 0px;
        margin-left: 0px;
    }

    .AdminTitle_Titlemenu_col3 {
        margin-right: 20px;
    }
    .Adminbasicinformation_outer {
        width: 80%;
        margin-bottom: 20px;
    }
    .adminrelated {
        width: 85%;
        border-radius: 5px;
    }
    .singleadminrelated_inner{
        gap:0;
        padding: 20px 15px;
    }
    .singleadminrelated_inner_label3{
        width: 28%;
    }
}