
.adminprofileeditcom{
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    /* margin-left:50px ; */
}
.adminprofileeditcom_row2{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    width: 90%;
}
.adminprofileeditcom_row2_col1{
    margin-right: 20px;
    display: none;
    /* margin-left: 100px; */
}
.adminprofileeditcom_row2_col2{
    width: 70%;
}
.adminprofileeditcom_row2_col2_innercol{
    margin-top: 20px;
    width: 100%;
}
.AdminUserProfile_container{
  margin-bottom: 15px;
    background-color:#FAFBFC;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    /* width: 30%; */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    
}
.AdminUserProfile_inner_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    margin: 35px;
}
.AdminUserProfile_inn_cont_row1{
    width: 100%;
    width: 120px;
    height: 120px;
    background-color:#C1F1F1;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    margin-bottom: 10px;
}
.AdminUserProfile_inn_cont_row1 img{
    width: 100%;
    width: 100px;
    height: 100px;
    border-radius: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
  

}
.AdminUserProfile_inn_cont_row2{
    width: 100%;
    
    align-items: center;
    text-align:center;
    color:#4E5E80; 
    font-weight: 600;
    font-size: 1rem;
    line-height: 28.12px;
    margin-top: 15px;
    margin-bottom: 15px;
}
.AdminUserProfile_inn_cont_row3{
    width: 100%;
    
    align-items: center;
    text-align:center;
    color:#ADB8CC;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 28.12px;
}
.adminprofileeditcom_row2_col2_innercol_map{
    width: 107.8%;
    display: none;
}

::placeholder{
    color:#cad0df ;
}
select{
    color:#cad0df ;
  
}

@media only screen and (max-width:500px){
    .adminprofileeditcom_row2{
        /* margin-left: 0px; */
    }
    .adminprofileeditcom{
        width: 135%;
        margin-left: -92px;
    }
    .adminuserlocationdetails_body_col2{
        margin-left: -32px;
        width: 68%;
    }
    .adminuserlocationdetails_body_col2_inner{
        margin-left: -70px;
    }
    .adminuserprofile_loader{
        position: absolute;
        margin-left: 25%;
        margin-top: 30%;
    }
}