.adminlist{
    background-color:white;
    padding: 20px;
    padding-top: 20px;
    padding-bottom: 50px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.adminlist_header{
    justify-content: center;
    display: flex;
}

.adminlist_heading{
    justify-content:space-between;
    display: flex;
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
    width: 100%;
    background-color: white;
}

.adminlist_heading label{
    color: #74829F;
    font-size: .8rem;
    font-weight: 550;
}

.adminlist_middle{
    padding-top: 20px;
    padding-bottom: 500px;
}

.adminlist_end{
    justify-content: center;
    display: flex;
}

.adminlist_end button{
    padding: 7px;
    border-radius: 18px;
    border: none;
    outline: none;
    color: #6C7A99;
    font-weight: 550;
    font-size: .7rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.singleadminlist{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
   
}

.singleadminlist_row{
    display: flex;
    justify-content: space-between;
    /* padding-left: 10px; */
    background-color: #FAFBFC;
    /* width: 92%; */
    align-items: center;
    padding: 20px;
    border-radius: 5px;
    /* background-color: #F6F5FF; */
    width: 100%;
    gap: 10px;
    
}

.singleadminlist_row_img{
    display: flex;
    align-items: center;
}

.singleadminlist_row img{
    border-radius: 3px;
    padding-left: 10px;
    /* display: flex; */
    /* border-bottom-right-radius: 3px; */
}

.singleadminlist_row label,span{
    font-size: .7rem;
    color: #74829F;
    font-weight: 550;
}

.singleadminlist_row_img{
    width: 5%;
}

.singleadminlist_row_producer{
    width: 11%;
}

.singleadminlist_row_product{
    width: 11%;
}

.singleadminlist_row_id{
    width: 11%;
}

.singleadminlist_row_cat{
    width: 11%;
}

.singleadminlist_row_link{
    width: 10%;
}

.singleadminlist_row_cost{
    width: 9.09%;
}

.singleadminlist_row_extra{
    width: 10.09%;
}

.singleadminlist_row_price{
    width: 8%;
    /* padding-left: 25px; */
}

.singleadminlist_row_priority{
    width: 11.18%;
    
}

.singleadminlist_row_edit{
    width: 3%;
}

.adminlist_heading_img{
    padding-left: 18px;
    width: 5%;
}

.adminlist_heading_producer{
    width: 9.09%;
    justify-content: center;
}
.adminlist_heading_producer1{
    width: 9.09%;
}

.adminlist_heading_product{
    width: 9.09%;
}

.adminlist_heading_id{
    width: 9.09%;
}

.adminlist_heading_cat{
    width: 9.09%;
}

.adminlist_heading_link{
    width: 9.09%;
}

.adminlist_heading_cost{
    width: 9.09%;
}

.adminlist_heading_extra{
    width: 9.09%;
}

.adminlist_heading_price{
    width: 9.09%;
}

.adminlist_heading_priority{
    width: 9.09%;
}

.adminlist_heading_edit{
    width: 5%;
}
.singleadminlist_row:hover{
    background-color: rgb(207, 178, 234);
    color: white;
}

@media only screen and (max-width:500px){
    .adminlist_heading label{
        font-size: .62rem;
    }
    .singleadminlist{
        width: 100%;
    }
    .singleadminlist_row label{
        font-size: .57rem;
    }
    .singleadminlist_row{
        /* width: 15%; */
        padding: 0px;
        gap:0;
        /* background-color: yellow; */
        padding-left: 10px;
    }
    .adminlist_heading_img{
        padding-left: 10px;
    }
    .adminlist_heading_cost{
        /* width: ; */
        display: flex;
        padding-left: 10px;
        justify-content: center;
    }
    .singleadminlist_row_edit{
        font-size: 12px;
    }
    
}
