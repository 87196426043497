.adminproductlist_row{
    display: flex;
    background-color: #F7F8FA;
}
.adminproductlist_row_col1{
display: flex;
}
.adminproductlist_row_col2{
    /* width: 80%; */
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 2%;
    width: 100%;
}

@media only screen and (max-width:500px){
    .adminproductlist_row_col2{
        margin-left:2px;
        width: 99%;
    }
}

@media only screen and (max-width:350px){
    .adminproductlist_row_col2{
        /* margin-left:2px; */
        width: 97.5%;
    }
    .adminproductlist_row{
        width: 99.9%;
    }
    .adminproductlist{
        width: 102%;
    }
    
}