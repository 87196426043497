.adminuserlist_row{
    display: flex;
    background-color: #F7F8FA;
}
.adminuserlist_row_col1{
display: flex;
}
.adminuserlist_row_col2{
    width: 100%;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 2%;
}

@media only screen and (max-width:500px){
    .adminuserlist_row_col2{
        margin-left: 0px;
    }
}