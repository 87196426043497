.admineditproduct {
  width: 100%;
  margin-bottom: 5%;
  /* display: fl; */
}

.adminproductmain {
  display: flex;
  background-color: #FFFFFF;
  padding: 20px;
  color: #6b7a99;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.7rem;
  font-weight: 500;
  /* width: 60%; */
}

.admineditproduct_main{
  display: none;
}

.adminproductmain_col2_row2_inner_row3 textarea {
  border: none;
  outline: none;
  width: 100%;
}

.adminproductmain_col1 {
  width: 50%;
  background-color: #fafbfc;
  border-radius: 5px;
  padding: 30px;
  margin: 0 9px;
}

.adminproductmain_col2 {
  width: 50%;
  /* background-color: rgb(246, 170, 170); */
  /* margin: 0 9px; */
}

.adminproductmain_col1_row2_outer label {
  padding: 0 5px;
}

.adminproductmain_col1_row1 {
  justify-content: space-between;
  display: flex;
}

.adminproductmain_col1_row1 label {
  /* padding-left: 10px; */
}

.adminproductmain_col1_row2 {
  padding-top: 20px;
  /* background-color: red; */
  display: flex;
}

.adminproductmain_col1_row2_col1 {
  width: 50%;
  /* background-color: aliceblue; */
  margin-right: 5px;
}

.adminproductmain_col1_row2_col2 {
  width: 50%;
  /* background-color:brown; */
  margin-left: 5px;
}

.adminproductmain_col1_row2_col1_row,
.adminproductmain_col1_row2_col2_row {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  /* display: none; */
}

.adminproductmain_col1_row2_col1_row label,
.adminproductmain_col1_row2_col2_row label {
  padding-bottom: 8px;
  /* display: flex; */
}

.adminproductmain_col1_row2_col1_row input,
.adminproductmain_col1_row2_col2_row input {
  width: 92%;
  padding: 9px;
  border: none;
  outline: none;
}

.adminproductmain_col1_row2_row1_col_input {
  background-color: white;
  /* width: 222px; */
}

.adminproductmain_col1_row2_row1_col_input select {
  width: 94%;
  border: none;
  outline: none;
  padding: 8px;
  color: #6b7a99;
  font-size: .7rem;
}

.adminproductmain_col1_row3 {
  width: 100%;
  /* background-color: blueviolet; */
}

.adminproductmain_col1_row3_row {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  /* background-color: aliceblue; */
}

.adminproductmain_col1_row3_row label {
  padding-bottom: 10px;
}

.adminproductmain_col1_row3_row_input {
  background-color: white;
  align-items: center;
  display: flex;
  padding-right: 10px;
  width: 112%;
}

.adminproductmain_col1_row3_row_input input {
  /* width: 90%; */
  padding: 10px;
  border: none;
  outline: none;
}

.adminproductmain_col1_row3_row1 {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
}

.adminproductmain_col1_row3_row1 label {
  padding-bottom: 8px;
}

.adminproductmain_col1_row3_row1 input {
  padding: 9px;
  border: none;
  outline: none;
}

/******************************box2 start*****************************/

.adminproductmain_col2_row1 {
  background-color: #fafbfc;
  width: 100%;
  margin-bottom: 8px;
  border-radius: 5px;
}

.adminproductmain_col2_row1_outer {
  padding: 30px;
}

.adminproductmain_col2_row1_outer_row1 {
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.adminproductmain_col2_row1_outer_row2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.adminproductmain_col2_row1_outer_row1 {
  padding-bottom: 20px;
}
.adminproductmain_col2_row1_outer_row2_col1{
  display: flex;
  column-gap: 50px;
  width: 100%;
  /* background-color: green; */
  /* justify-content: space-between; */
}

.adminproductmain_col2_row1_outer_row2_col1_row {
  display: flex;
  flex-direction: column;
  /* background-color: yellow; */
  width:  99%;
}

.adminproductmain_col1_row3_row1 {
  display: flex;
  flex-direction: row;
}

.adminproductmain_col1_row3_row2 {
  display: flex;
  flex-direction: row;
}

.adminproductmain_col1_row3_row3 {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}

.adminproductmain_col1_row3_row3_checkbox {
  padding-left: 34px;
}

.adminproductmain_col1_row3_row2_checkbox {
  padding-left: 11px;
}

.adminproductmain_col1_row3_row2,
.adminproductmain_col1_row3_row1 {
  padding-top: 10px;
}

.adminproductmain_col2_row1_outer_row2_col1_row label {
  padding-top: 15px;
  padding-bottom: 10px;
}

.adminproductmain_col2_row1_outer_row2_col1_row input {
  width: 100%;
  padding: 9px;
  border: none;
  outline: none;
}

.adminproductmain_col2_row1_outer_row2_col1_row select {
  width: 90px;
  padding: 9px;
  border: none;
  outline: none;
  /* color: #c3cad9; */
  color: #6b7a99;
  font-size: .7rem;
}


.adminproductmain_col2_row1_outer_row2_col1_row_select {
  background-color: white;
}

.adminproductmain_col2_row1_outer_row2_col1_row_select input{
  width: 100%;
}
.adminproductmain_col2_row1_outer_row2_col1_row_select select{
width: 100%;
font-size: .8rem;
  color: black;
}
.adminproductmain_col2_row1_outer_row2_col1_row_select1 input{
  width: 92%;
}
.adminproductmain_col2_row1_outer_row2_col1_row_select2 select{
  font-size: .8rem;
  color: black;
  width: 100%;
}
/***********************box3 start***********************/


.adminproductmain_col2_row2 {
  padding: 30px;
  margin-top: 8px;
  background-color: #fafbfc;
  padding-bottom: 40px;
  border-radius: 5px;
}

.adminproductmain_col2_row2_inner_row1 {
  display: flex;
  justify-content: space-between;
}

.adminproductmain_col2_row2_inner_row2 {
  /* padding-top: 20px; */
  /* padding-bottom: 20px; */
}

.adminproductmain_col2_row2_inner_row2_row1 {
  justify-content: space-between;
  display: flex;
  display: none;
}

.adminproductmain_col2_row2_inner_row3 {
  padding-top: 10px;
}

.adminproductmain_col2_row2_inner_row3 label {
  color: #4D5E80;
}

.adminproductmain_col1_row3_row4 {
  display: flex;
  flex-direction: column;
  /* padding-top: 10px; */
  gap: 10px;
  padding-left: 43px;
}

.adminproductmain_col1_row3_row5 {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}

.adminproductmain_col1_row3_row5_checkbox {
  padding-left: 9px;
}

.adminproductmain_col1_row3_row4_input input {
  border: none;
  outline: none;
  padding: 10px;
  width: 90%;
}

.adminproductmain_col2_row2_inner_row4 {
  padding-top: 35px;
}

.adminproductmain_col2_row2_inner_row4 label {
  color: #6b7a99;
}

.adminproductmain_col1_row3_col {
  display: flex;
  flex-direction: row;

  padding-bottom: 15px;
}

.adminproductmain_col1_row3_row4_input {
  background-color: white;
  align-items: center;
  display: flex;
  padding-right: 10px;
  width: 112%;
}

/* .adminproductmain_col1_row3_row1_cont {
  display: flex;
  flex-direction: row;
  gap: 1px;
  padding-top: 20px;

} */
.adminproductmain_col2_row1_outer_row2_col1_row_select{
  background-color: yellow;
}
.adminproductmain_col1_row2_col1_row_select select{
  width: 100%;
  padding: 9px;
  border: none;
  outline: none;
}
.adminproductmain_col1_row2_col1_row_cont_label{
  display: block;
  width: max-content;
}
.adminproductmain_col1_row2_col1_row_cont{
  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: 5px;
  padding-top: 20px;
}
.adminproductmain_col1_row2_col1_row1{
  display: flex;
  flex-direction: row;
  width: max-content;
  gap: 25px;
}

::placeholder{
  color: #d8deea;
}

/* select {
  color: #c0cade;
} */
.productmain_select{
  color: black;
}
.productmain_select option{
  color: black;
} 

@media only screen and (max-width:500px){
  .adminproductmain{
    display: flex;
    flex-direction: column;
    /* background-color: yellow; */
  }
  .adminproductmain_col1{
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    width: 87%;
    margin-left:0px;
      /* background-color: yellow; */
  }
  .adminproductmain_col2{
    margin-left: 10px;
    display: contents;

  }
  .adminproductmain_col1_row2_col1_row1{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }
}

@media only screen and (max-width:350px){
  .adminproductmain_col2{
    margin-left: 0px;
    width: 100%;
    background-color: yellow;
  }
  .adminproductmain_col2_row1_outer_row2_col1{
    column-gap: 0px;
  }
  .adminproductmain_col2_row1_outer_row2_col1_row input{
    width: 75%;
  }
  .adminproductmain{
    padding: 0;
  }
  .adminproductmain_col2_row1_outer_row2_col1_row_select input{
    width:83px;
  }
  .adminproductmain_col1{
    width: 84%;
  }
}