.adminuseranalytics{
    background-color: #F6F5FF;
   display: flex;
   flex-direction: column;
   width: 200px;
   height: auto;
   /* margin: 30%; */
   text-align: center;
   padding: 55px 30px;
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   border-radius: 8px;
   margin-top: 18px;
}
.adminuseranalytics_header{
   display: flex;
   flex-direction: row;
   width: 100%;
}
.adminuseranalytics_header_left{
   font-style: normal;
   font-weight: 600;
   font-size: 1rem;
   line-height: 16px;
   color: #7D8FB3;
   width: 80%;
}
.adminuseranalytics_header_right{
   font-style: normal;
   font-weight: 600;
   font-size: 1rem;
   line-height: 16px;
   color: #7D8FB3;
}
.adminuseranalytics_cont{
   display: grid;
   gap: 25px;
   padding-top: 50px;
}
.adminuseranalytics_details{
   display: flex;
   flex-direction: column;
   
}
.adminuseranalytics_details_col1{
   
   font-style: normal;
   font-weight: 600;
   font-size: 1.2rem;
   line-height: 16px;
   color: #315191;
   padding-bottom: 20px;
}
.adminuseranalytics_details_col2{
  
   font-style: normal;
   font-weight: 600;
   font-size: 0.8rem;
   line-height: 16px;
   color: #C3CAD9;
   padding-bottom: 20px;
}