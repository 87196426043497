.adminproductmediapage{
    width: 100%;
}
.adminproductmediapage_row2_col2_row1{
    display: flex;
    flex-direction: column;
    gap:20px;
    width: 112%
}
.adminproductmediapage_row2_col2_inner_row2{
    display: flex;
    width: 80%;
    margin-left: 100px;
    margin-top: 80px;
    margin-bottom: 2%;
    /* background-color: yellow; */
    /* flex-direction: row;
    gap: 20px; */
    /* padding-top: 50px; */
    justify-content: center;
    align-content: center;
}
.adminproductmediapage_row2_col2{
    display: flex;
    flex-direction: column;
    padding: 20px;
   
}
.adminproductmediapage_row2_col1{
    display: contents;
}
.adminproductmediapage_row2{
    display: flex;
    flex-direction: row;
}
.adminproductmediapage_row2_col2_inner_row3{
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding-top: 20px;
}
.adminproductmediapage_row2_col2_inner{
    display: flex;
    flex-direction: column;
    padding: 20px;
}
