.adminaddproduct_row{
    display: flex;
    background-color: #F7F8FA;
}
.adminaddproduct_row_col1{
display: flex;
}
.adminaddproduct_row_col2{
    width: 80%;
    margin-left: 100px;
    margin-top: 80px;
    margin-bottom: 2%;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

}

@media only screen and (max-width:500px){
    .adminaddproduct_row_col2{
        margin-left: 0;
        width: 100%;
    }
}

@media only screen and (max-width:350px){
    .adminaddproduct_row_col2{
        margin-left: 0;
        width: 100%;
    }
    .adminaddproduct{
        width: 104.1%;
    }
}