.AdminLeftBar_body {
    display: flex;
    flex-direction: column;
    width: 80px;
    background-color: #F5F5F5;
    padding-bottom: 90px;
    border-right: 1px solid silver;
    /* height: 100vh; */
    /* align-items: center; */
    /* background-color: #44bfcd;   */
}

.AdminLeftBar_top {
    display: flex;
    flex-direction: column;
    width: 50%;
    /* margin-bottom: 20%; */
    padding-right: 30px;
    /* padding-top: 20px; */
    padding-left: 5px;
    /* margin-bottom: 115px; */
    /* padding-bottom: 280px; */

}

.AdminLeftBar_button button {
    display: flex;
    border: none;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    border-radius: 300px;
    font-size: 35px;
    /* background-color: rgb(234, 140, 156); */
    background-color: rgb(237, 105, 127);
    /* background-color: #bb3030; */

}

.AdminLeftBar_row2 {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.AdminLeftBar_button {

    /* justify-content: center; */
    /* align-items: center; */
    padding-bottom: 10px;
    padding-top: 8px;
    padding-left: 15px;
    padding-right: 20px;

}
.AdminLeftBar_button :hover ~ .AdminLeftBar_button_label{
    display: flex;
    justify-content: center;
}
.AdminLeftBar_button_label{
    display: none;
    color: #C3CAD9;
    margin-left: -15px;
    font-size: .85rem;
position: absolute;}

.AdminLeftBar_bottom {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-bottom: 20px;
    padding-right: 40px;
    padding-top: 250px;
    padding-left: 15px;
}

.AdminLeftBar_bottom_image img {
    width: 35px;
    height: 35px;
    border-radius: 50px;



}

.AdminLeftBar_bottom_image {
    padding-bottom: 15px;
}

.AdminLeftBar_button2 button {
    display: flex;
    border: none;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    border-radius: 300px;
    font-size: 35px;
    background-color: #FFFFFF;
    border-color: aqua;
    border-width: 2px;
    /* background-color: #bb3030; */
}

.AdminLeftBar_button2 {
    padding-bottom: 15px;
}

@media only screen and (max-width:500px){
    .AdminLeftBar_body{
        display: none;
    }
}