.admintopbar{
    /* background-color: yellow; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 50px;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); */
    background-color: #F5F5F5;
    border-bottom: 1px solid silver;
}

.admintopbar_col1_circle{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    /* background-color: #c90053cf; */
    background-color:rgb(237, 105, 127);
    display: flex;
    justify-content: center;
    align-items: center;
}
.admintopbar_col2_icon img{
    width: 50px;
    height: 50px;
}

.admintopbar_col1{
    display: flex;
    align-items: center;
}

.admintopbar_col1 label{
    padding-left: 30px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 570;
    color: #a2b0d1;
    /* color:rgb(60, 60, 92); */
}
.admintopbar_col2_logout{
    /* background-color: rgb(249, 242, 242); */
    background-color: white;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border: 1px solid silver;
    padding: 20px;
    z-index: 1;
    position:absolute;
    display: none;
    margin-left: -20px;
    color:#a2b0d1;
    margin-top: -5px;
    border-radius: 5px;
}
.admintopbar_col2 :hover ~ .admintopbar_col2_logout{
    display: flex;
    justify-content: center;
}
.admintopbar_col2_logout:hover{
    display: block;
}
.admintopbar_col1_circle_menu{
    /* width: 13%;
    background-color: white;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border: 1px solid silver;
    padding-top: 10px;
    z-index: 1;
    position:absolute;
    display: none;
    margin-left: 65px;
    color:#a2b0d1;
    margin-top: 40%;
    border-radius: 2px;   */
    display: none;
}
/* .admintopbar_col1_circle :hover ~ .admintopbar_col1_circle_menu{
    display: block;
    flex-direction: column;
}
.admintopbar_col1_circle_menu:hover{
    display: block;
} */
.Menulist{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    /* border-bottom: 1px solid silver; */
    margin-left: 0;
    padding-left: 0;
}
.Menulist label{
    font-size: .9rem;
    padding-bottom: 10px;
    border-bottom: 1px solid silver;
    width: 70%;
    margin-left: 15px;
    padding-left: 0;
}

@media only screen and (max-width:500px){
    .admintopbar_col1_circle_menu{
        display: flex;
        width: 89%;
        position: absolute;
        /* width: 25%; */
        margin-top: 87%;
        margin-left: 20%;
        /* padding-left: 10%; */
    }
    .Menulist label{
        font-size: .6rem;
    }
    
}

@media only screen and (max-width:350px){
    /* .admintopbar{
        width: 69.7%;
    } */
    .admintopbar_col1_circle_menu{
        display: flex;
        width: 25%;
        margin-top: 166%;
        margin-left: 0px;
    }
    .Menulist label{
        font-size: .6rem;
    }
    
    /* .admintopbar{
        width: 76%;
    } */
}
