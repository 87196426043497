.adminlistuser{
    background-color:white;
    padding: 20px;
    padding-top: 20px;
    padding-bottom: 50px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.adminlistuser_header{
    justify-content: center;
    display: flex;
}

.adminlistuser_heading{
    /* justify-content:space-between; */
    display: flex;
    width: 100%;
    background-color: white;
    gap: 52px;
}

.adminlistuser_heading label{
    color: #74829F;
    font-size: .8rem;
    font-weight: 550;
}

.adminlistuser_middle{
    padding-top: 20px;
    padding-bottom: 400px;
}

.adminlistuser_end{
    justify-content: center;
    display: flex;
    padding: 10px;
}

.adminlistuser_end button{
    padding: 7px;
    border-radius: 18px;
    border: none;
    outline: none;
    color: #6C7A99;
    font-weight: 550;
    font-size: .7rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.singleadminlistuser{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
}

.singleadminlistuser_row{
    display: flex;  
    background-color: #FAFBFC;
    width: 100%;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    gap: 10px;

}

.singleadminlistuser_row_img{
    display: flex;
    align-items: center;
}

.singleadminlistuser_row img{
    border-radius: 3px;
    padding-left: 10px;
    /* display: flex; */
    /* border-bottom-right-radius: 3px; */
}

.singleadminlistuser_row label,span{
    font-size: .7rem;
    color: #74829F;
    font-weight: 550;
}

.singleadminlistuser_row_img{
    width: 14.28%;
}

.singleadminlistuser_row_producer{
    width: 14.28%;
}

.singleadminlistuser_row_product{
    width: 14.28%;
}

.singleadminlistuser_row_id{
    width: 14.28%;
}

.singleadminlistuser_row_cat{
    width: 18.28%;
}

.singleadminlistuser_row_link{
    width: 14.28%;
}

.singleadminlistuser_row_cost{
    width: 14.28%;
}

/* .singleadminlistuser_row_extra{
    width: 14.28%;
} */

/* .singleadminlistuser_row_price{
    width: 14.28%;
} */
/* 
.singleadminlistuser_row_priority{
    width: 14.28%;
} */

.singleadminlistuser_row_edit{
    width: 14.28%;
    padding-left: 10px;
}

.adminlistuser_heading_img{
    width: 7.28%;
}

.adminlistuser_heading_producer{
    width: 9%;
    /* padding-left: 10px; */
}

.adminlistuser_heading_product{
    width: 8.5%;
}

.adminlistuser_heading_id{
    width: 11%;
}

.adminlistuser_heading_cat{
    width: 8%;
}
.adminlistuser_heading_link{
    width: 8.50%;
    /* padding-left: 10px; */
}

.adminuserlist_heading_cost{
    width: 6%;
    padding-left: 20px;
}

/* .adminlistuser_heading_extra{
    width: 14.28%;
} */

/* .adminlistuser_heading_price{
    width: 14.28%;
} */

/* .adminlistuser_heading_priority{
    width: 14.28%;
} */

.adminlistuser_heading_edit{
    width: 10.28%;
    padding-left: 39px;

}
.singleadminlistuser_row:hover{
    background-color: rgb(207, 178, 234);
    color: white;
}
.singleadminlistuser_row_img_icon{
    padding-left: 10px;
}
 @media only screen and (max-width:500px){
    .adminlistuser_heading {
        gap:12px;
    }
    .singleadminlistuser_row_img,
    .adminlistuser_heading_img{
        display: none;
    }
    .adminlistuser_heading label{
        font-size: .7rem;
    }
    .adminlistuser_heading_producer{
        width: 12%;
    }
    .adminlistuser_heading_id{
        width: 15%;
    }
    .adminlistuser_heading_cat{
        width: 20%;
        display: flex;
        justify-content: center;
    }
    .adminlistuser_heading_link{
        width: 12%;
        display: flex;
        justify-content: center;
        padding-left: 20px;
    }
    .adminuserlist_heading_cost{
        width: 5%;
    }
    .adminlistuser_heading_edit{
        width: 1%;
    }
    .singleadminlistuser_row_link{
            width: 25%;
            display: flex;
            justify-content: center;
    }
    /* .singleadminlistuser_row_cost{
        width: 15%;
        justify-content: center;
        display: flex;
    } */
    .singleadminlistuser_row_edit{
        width: 4%;
    }

 }
