.adminorderlist_row{
    display: flex;
    background-color: #F7F8FA;
}
.adminorderlist_row_col1{
display: flex;
}
.adminorderlist_row_col2{
    width: 100%;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 2%;
}
.adminorderedit_row_col2{
    display: flex;
    flex-direction: row;
}
@media only screen and (max-width:500px) {
    .adminorderlist_row_col2_row1{
        width: 53%;
    }
    .adminorderlist_row_col2{
        margin-left: 0px;
    }
}