.adminsliderpage_row2{
    display: flex;
    flex-direction: row;
}
.adminsliderpage_row2_col1{
    display: flex;
}
.adminproductmediapage_row2_col2_inner_row2{
    /* width: 100%; */
}

@media only screen and (max-width:500px){
    .adminproductmediapage_row2_col2_inner_row2{
        margin-left: 0px;
        width: 100%;
    }
}